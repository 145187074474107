<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>

    <a-card>
      <detail-list title="支付宝信息" style="width: 100%" v-if="withdrawType == 1">
        <detail-list-item term="二维码" style="width: 100%"><img style="width: 60px;height: 60px;" preview="人像面" :src="form.zfbQrCode" v-if="form.zfbQrCode != null && form.zfbQrCode != ''"></detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="认证姓名" style="width: 100%">{{ form.zfbName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
      </detail-list>

      <detail-list title="微信信息" style="width: 100%" v-if="withdrawType == 2">
        <detail-list-item term="二维码" style="width: 100%"><img style="width: 60px;height: 60px;" preview="人像面" :src="form.wxQrCode" v-if="form.wxQrCode != null && form.wxQrCode != ''"></detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="认证姓名" style="width: 100%">{{ form.wxName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
      </detail-list>

      <detail-list title="银行卡信息" style="width: 100%" v-if="withdrawType == 3">
        <detail-list-item term="姓名" style="width: 100%">{{ form.cardRealName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="银行名稱" style="width: 100%">{{ form.cardBankName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="银行卡号" style="width: 100%">{{ form.cardBankNo }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="开户行" style="width: 100%">{{ form.cardBankOpening }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
      </detail-list>

      <a-divider style="margin-bottom: 32px"/>

    </a-card>
  </a-drawer>
</template>

<script>
import { getUserAccount } from '@/api/platform/userWithdraw'
import DetailList from '@/components/tools/DetailList'
const DetailListItem = DetailList.Item;

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    DetailList,
    DetailListItem
  },
  data () {
    return {
      submitLoading: false,
      withdrawType: null,
      showVer: false,
      formTitle: '',
      // 表单参数
      form: {
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    changeStatus(status){
      if(status == 1){
        this.showVer = false;
      }
      if(status == 2){
        this.showVer = true;
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    reset () {
      this.formType = 1
      this.form = {
      }
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.userId : ids
      this.withdrawType = row.withdrawType;
      getUserAccount({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '绑定信息'
      })
    },
  }
}
</script>
<style>
.ant-divider-horizontal {
  display: none;
}
</style>